<template>
  <AccountBanksEditAdmin
          :AB="AB"
          :optionsAccountBankTypes="optionsAccountBankTypes"
          @save="save({edit: true})"
  />
</template>

<script>
  import AccountBanksEditAdmin from "./AccountBanksEditAdmin/AccountBanksEditAdmin";
  import {AccountBanks} from "../../models/AccountBanks";
  import {accountBanksMixin} from "../../../../../mixins/accountBanks/accountBanksMixin";

  export default {
    name: "AccountBanksEdit",
    components: {
      AccountBanksEditAdmin,
    },

    mixins: [accountBanksMixin],

    data() {
      return {
        AB: new AccountBanks(),
        optionsAccountBankTypes: [],
      }
    },

    mounted() {

      this.AB.setId(this.$route.params.id)

      this.$store.dispatch('getAccountBanks', this.AB.getId()).then(() => {
        this.AB.setItem(this.$store.getters.getAccountBanksItem)
        // this.selectAccountBankType()
      }).catch(error => this.createErrorLog(error))
    },

    methods: {
      // selectAccountBankType(){
      //   this.$store.dispatch('fetchAccountBankType').then((response) => {
      //     this.optionsAccountBankTypes = this.getRespPaginateData(response)
      //   })
      // }
    },

  }
</script>

<style scoped>

</style>
