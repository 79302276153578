
<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">


      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['accountTypes_accountBankTypes'])"></div>
        <DefaultSelect
            :label="$t('accountTypes_accountBankTypes.localization_value.value')"
            :options="optionsAccountBankTypes"
            :optionsLabel="'name'"
            :selected="getAccountBankTypesSelected()"
            @change="changeAccountBankTypes"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['accountTypes_accountBankTypes'])"></div>
        <DefaultInput
            :label="$t('accountTypes_Name.localization_value.value')"
            :type="'text'"
            v-model="name"
        />
      </div>



    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "BelposhtaFilter",
    components: {
      FilterBlock,
      MainButton,
      DefaultInput,
      DefaultSelect,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        optionsAccountBankTypes: [],
        optionsAccountBankTypesSelected: '',

        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        name: this.filterGetParams.name ? this.filterGetParams.name : '',
        typeId: this.filterGetParams.typeId ? this.filterGetParams.typeId : '',

        filterCounts: [
          'id',
          'name',
          'typeId',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.id = newVal.id ? newVal.id : ''
        this.name = newVal.name ? newVal.name : ''
        this.typeId = newVal.typeId ? newVal.typeId : ''
      },
    },

    mounted() {
      this.$store.dispatch('fetchAccountBankType').then((response) => {
        this.optionsAccountBankTypes = this.getRespPaginateData(response)
        this.getAccountBankTypesSelected()
      })
    },

    methods: {
      getAccountBankTypesSelected(){
        if(this.typeId === '') return ''
        return this._.find(this.optionsAccountBankTypes, {id: parseInt(this.typeId)})
      },

      changeAccountBankTypes(val){
        this.typeId = val.id
      },

      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

    },
  }
</script>

<style scoped>

</style>
