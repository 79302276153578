<template>
  <div class="order-create__section">
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--83 custom-col--md-100 mb-0">
        <div class="order-create__row custom-row">
          <div class="custom-col custom-col--50 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['accountTypes_accountBankTypes'])"></div>
            <DefaultSelect
                :options="optionsAccountBankTypes"
                :label="$t('accountTypes_accountBankTypes.localization_value.value')"
                :optionsLabel="'name'"
                v-bind:class="{'ui-no-valid': AB.validation.accountBankTypesSelected}"
                @change="selectAccountBankTypes"
                :errorTxt="$t(`${AB.validationTranslate.accountBankTypesSelected}.localization_value.value`)"
                :error="AB.validation.accountBankTypesSelected"
                :selected="AB.data.accountBankTypesSelected"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="order-create__row custom-row order-wrap">
      <div class="custom-col custom-col--83 custom-col--md-100">
        <div class="order-create__row custom-row">
          <div class="custom-col custom-col--50 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['accountTypes_Name'])"></div>
            <DefaultInput
                    :label="$t('accountTypes_Name.localization_value.value')"
                    v-bind:class="{'ui-no-valid': AB.validation.name}"
                    :error="AB.validation.name"
                    :errorTxt="$t(`${AB.validationTranslate.name}.localization_value.value`)"
                    v-model="AB.data.name"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['accountTypes_NameEn'])"></div>
            <DefaultInput
                    :label="$t('accountTypes_NameEn.localization_value.value')"
                    v-bind:class="{'ui-no-valid': AB.validation.nameEn}"
                    :error="AB.validation.nameEn"
                    :errorTxt="$t(`${AB.validationTranslate.nameEn}.localization_value.value`)"
                    v-model="AB.data.nameEn"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['accountTypes_NameUa'])"></div>
            <DefaultInput
                    :label="$t('accountTypes_NameUa.localization_value.value')"
                    v-bind:class="{'ui-no-valid': AB.validation.nameUa}"
                    :error="AB.validation.nameUa"
                    :errorTxt="$t(`${AB.validationTranslate.nameUa}.localization_value.value`)"
                    v-model="AB.data.nameUa"
            />
          </div>
<!--          <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100"-->
<!--               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['accountTypes_NameBel'])"></div>-->
<!--            <DefaultInput-->
<!--                    :label="$t('accountTypes_NameBel.localization_value.value')"-->
<!--                    v-bind:class="{'ui-no-valid': AB.validation.nameBel}"-->
<!--                    :error="AB.validation.nameBel"-->
<!--                    :errorTxt="$t(`${AB.validationTranslate.nameBel}.localization_value.value`)"-->
<!--                    v-model="AB.data.nameBel"-->
<!--            />-->
<!--          </div>-->
          <div class="custom-col custom-col--50 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['accountTypes_NameRu'])"></div>
            <DefaultInput
                    :label="$t('accountTypes_NameRu.localization_value.value')"
                    v-bind:class="{'ui-no-valid': AB.validation.nameRu}"
                    :error="AB.validation.nameRu"
                    :errorTxt="$t(`${AB.validationTranslate.nameRu}.localization_value.value`)"
                    v-model="AB.data.nameRu"
            />
          </div>
          <div class="custom-col custom-col--25 custom-col--md-50 custom-col--sm-50"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['accountTypes_SystemTypeNumber'])"></div>
            <DefaultInput
                    :label="$t('accountTypes_SystemTypeNumber.localization_value.value')"
                    v-bind:class="{'ui-no-valid': AB.validation.systemNumber}"
                    :error="AB.validation.systemNumber"
                    :errorTxt="$t(`${AB.validationTranslate.systemNumber}.localization_value.value`)"
                    v-model="AB.data.systemNumber"
            />
          </div>
        </div>


        <div class="order-create__row custom-row">
<!--          <div class="custom-col mb-3"-->
<!--               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['accountTypes_Individual'])"></div>-->
<!--            <DefaultCheckbox-->
<!--                    :label="$t('accountTypes_Individual.localization_value.value')"-->
<!--                    :value="AB.data.individual"-->
<!--                    @input="AB.data.individual = !AB.data.individual"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="custom-col mb-3"-->
<!--               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['accountTypes_Business'])"></div>-->
<!--            <DefaultCheckbox-->
<!--                    :label="$t('accountTypes_Business.localization_value.value')"-->
<!--                    :value="AB.data.business"-->
<!--                    @input="AB.data.business = !AB.data.business"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="custom-col mb-3"-->
<!--               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['accountTypes_ForAdmin'])"></div>-->
<!--            <DefaultCheckbox-->
<!--                    :label="$t('accountTypes_ForAdmin.localization_value.value')"-->
<!--                    :value="AB.data.forAdmin"-->
<!--                    @input="AB.data.forAdmin = !AB.data.forAdmin"-->
<!--            />-->
<!--          </div>-->
          <div class="custom-col mb-3"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['accountTypes_AllowToWithdrow'])"></div>
            <DefaultCheckbox
                    :label="$t('accountTypes_AllowToWithdrow.localization_value.value')"
                    :value="AB.data.allowPrivatBank"
                    @input="AB.data.allowPrivatBank = !AB.data.allowPrivatBank"
            />
          </div>
          <div class="custom-col mb-3"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['accountTypes_enableBank'])"></div>
            <DefaultCheckbox
                    :label="$t('accountTypes_enableBank.localization_value.value')"
                    :value="AB.data.enable"
                    @input="AB.data.enable = !AB.data.enable"
            />
          </div>
        </div>
      </div>
      <div class="custom-col custom-col--16 custom-col--md-100 logo">
        <!--<DropZone class="drop-zone-bg"-->
                  <!--:parentFiles="files"-->
                  <!--@changeImg="changeImg"-->
                  <!--:maxSize="'5'"-->
        <!--/>-->
        <FileBox class="w-100" @change="changeFile"/>
      </div>
    </div>

  </div>
</template>

<script>
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import FileBox from "../../../../../coreComponents/FileBox/FileBox";
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  export default {
    name: "StepContent",

    components: {
      FileBox,
      DefaultCheckbox,
      DefaultInput,
      DefaultSelect,
    },

    props: {
      AB: Object,
      optionsAccountBankTypes: Array
    },

    data() {
      return {
      }
    },

    mounted() {

    },

    methods: {
      changeFile(data) {
        this.AB.data.Files.setFiles([data])
      },

      selectAccountBankTypes(val){
        if(val === '') {
          this.AB.setAccountBankTypesSelected('')
          return
        }
        this.AB.setAccountBankTypesSelected(val)
      },
    },
  }
</script>

<style scoped lang="scss">
  .w-100{
    max-width: 100%;
  }
</style>
