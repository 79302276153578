<template>
  <div class="fragment">

<!--    <SearchEngine/>-->

    <div class="fragment">
      <div class="content-top-line content-top-line--with-bottom-line">
        <div class="content-tabs content-tabs--separator">

        </div>

        <div class="content-top-line-wrap">
          <ToggleFilterButton :count="countFilterParams"
                              @toggleFilter="$emit('toggleFilter')"
                              :showFilter="showFilter"
          />
        </div>
      </div>

      <div class="light-bg-block">
        <div class="btn-right-block"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['accountTypes_NewAccount'])"></div>
          <router-link :to="$store.getters.GET_PATHS.accountTypesCreate">
            <MainButton class="white-space-nowrap scoped-btn"
                        :value="'+ ' + $t('accountTypes_NewAccount.localization_value.value')"
            >
            </MainButton>
          </router-link>
        </div>
      </div>

    </div>

  </div>
</template>



<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";


  export default {
    name: "AccountBanksHead",
    components: {
      MainButton,
      // SearchEngine,
      ToggleFilterButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    data(){
      return{

      }
    },

    methods: {

    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";
  @import "../../../../../../../scss/colors";

  .scoped-btn{
    height: 40px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
  }
</style>

