<template>
  <div class="user-info-admin">
    <div class="user-info-admin__inner">
      <div class="user-info-admin__caption"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['accountTypes_Created'])"></div>
        {{$t('accountTypes_Created.localization_value.value')}}
      </div>

      <div class="user-info-admin__large">
        <template v-if="AB.data.accountBankTypesSelected['created_at']">
          {{AB.data.accountBankTypesSelected['created_at'] | moment("DD MMM, YYYY")}}
        </template>
        <template v-else>
          {{ new Date() | moment("DD MMM, YYYY") }}
        </template>
      </div>
<!--      <div class="user-info-admin__history"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>-->
<!--        <div class="site-link text-decoration-underline btn-style brown"-->
<!--             @click="changeHistoryOfChanges(true)"-->
<!--        >-->
<!--          {{$t('common_CheckHistory.localization_value.value')}}-->
<!--        </div>-->
<!--      </div>-->
    </div>



  </div>
</template>

<script>


  export default {
    name: "UserInfo",
    components: {
    },

    props: {
      AB: Object,
    },

    data(){
      return{
        isModalHistoryOfChanges: false,
      }
    },

    methods:{
      changeHistoryOfChanges(val){
        this.isModalHistoryOfChanges= val
      },
    }
  }
</script>

<style scoped>

  @media(min-width: 680px){
    .user-info-admin__large{
      font-weight: bold;
      font-size: 40px;
      line-height: normal;
    }
  }
</style>
