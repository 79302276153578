<template>
  <div class="file-box"
       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
    <div class="admin-edit" @click="editTranslate(['common_DropOrBrowse'])"></div>
    <label class="file-box__file-label">
      <input type="file"
             ref="fileInput"
             :accept="'image/svg+xml'"
             @change="changeFile">

      <div class="file-box__file-img"
           v-if="files.url"
      >
        <img :src="files.url" alt="ico">
      </div>
      <div class="file-box__file-img" v-else>
        <img :src="'/img/UI-group/file-floppy-icon.svg'" alt="ico">
      </div>
      <div class="file-box__file-text" v-html="$t('common_DropOrBrowse.localization_value.value')">
        <!--Drop or <span class="text-decoration-underline">browse</span>-->
      </div>
    </label>
  </div>
</template>

<script>
  export default {
    name: "FileBox",

    data() {
      return {
        files: {},
      }
    },

    methods: {
      changeFile(e) {
        // let file = e.target.files;
        // let data = {
        //   item: file,
        //   url: URL.createObjectURL(file[0])
        // }
        // this.files = data

        let file = e.target.files
        let f = e.target.files[0]
        let reader = new FileReader()

        reader.onload = (() => {
          return (e) => {
            let binaryData = e.target.result

            if (f.type !== 'image/svg+xml')
              return
            let base64String = window.btoa(binaryData)

            let data = {
              item: file,
              url: URL.createObjectURL(f),
              base64String: base64String,
              binaryData: binaryData
            }
            this.files = data
            this.$emit('change', data)

          };
        })(f, file);

        reader.readAsBinaryString(f);
      },

    }
  }
</script>

<style scoped lang="scss">

  @import "../../../scss/colors";
  @import "../../../scss/mixins/mixins";

  .file-box {
    max-width: 105px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    @include for-680 {
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin-bottom: 35px;
    }

    input[type="file"] {
      //display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &__file-label {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      position: relative;
    }

    &__file-img {
      display: flex;
      flex-direction: column;

      img {
        width: 105px;
        height: 105px;
        object-fit: contain;
      }
    }

    &__file-text {
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $brown;
    }
  }

</style>
