<template>
  <AccountBanksTableAdmin
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @changeFilter="changeFilter"
      @reload="reload"
  />
</template>

<script>
  import AccountBanksTableAdmin from "./AccountBanksTableAdmin/AccountBanksTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";


  export default {
    name: "AccountBanksTable",
    components: {
      AccountBanksTableAdmin,
    },

    mixins: [routeFilter],

    watch: {
      loadUserAuthorizedData: function () {
        if(!this.isAdmin) {
          return this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      },
    },

    data(){
      return {
        forPage: this.$store.getters.getAccountBanksForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterName: '',
        filterTypeId: '',

        countFilterParams: 0,
        filterGetParams: {},
      }
    },


    mounted() {

      if(this.loadUserAuthorizedData){
        if(!this.isAdmin) {
          return this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      }

      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          id: this.$route.query.id,
          name: this.$route.query.name,
          typeId: this.$route.query.typeId,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextAccountBanksPage', true)
        this.$store.dispatch('fetchAccountBanks', url).then(() => {
          this.$store.commit('setNextAccountBanksPage', false)
          this.$store.commit('setGlobalUpdateTable', true)
        })

        this.checkCountFilter()

      },

      generateFilterUrl(page, forPage) {

        let myQuery = this.createQueryBuilderObject()

        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'id',
              filterName: 'name',
              filterTypeId: 'type_id',
            },
        )

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }
  }
</script>

<style scoped>

</style>
