import { render, staticRenderFns } from "./AccountBanksTableAdmin.vue?vue&type=template&id=460b9d0e&scoped=true"
import script from "./AccountBanksTableAdmin.vue?vue&type=script&lang=js"
export * from "./AccountBanksTableAdmin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "460b9d0e",
  null
  
)

export default component.exports