import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "../../../globalModels/Files";

export function AccountBanks() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    typeId: '',
    status: '',
    name: '',
    nameEn: '',
    nameUa: '',
    nameRu: '',
    enable: false,

    systemNumber: '',
    allowPrivatBank: false,
    accountBankTypesSelected: '',


    Files: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3
    }),
  }

  this.validation = {
    name: false,
    nameEn: false,
    nameUa: false,
    nameRu: false,
    // systemNumber: false,
    accountBankTypesSelected: false,
  }

  this.validationTranslate = {
    name: '',
    nameEn: '',
    nameUa: '',
    nameRu: '',
    // systemNumber: '',
    accountBankTypesSelected: '',
  }

  this.validationTxt = {
    name: false,
    nameEn: false,
    nameUa: false,
    nameRu: false,
    // systemNumber: false,
    accountBankTypesSelected: false,
  }



  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getStatus = () => {
    return this.data.status
  }
  this.getTypeId = () => {
    return this.data.typeId
  }
  this.getName = () => {
    return this.data.name
  }
  this.getNameEn = () => {
    return this.data.nameEn
  }
  this.getNameUa = () => {
    return this.data.nameUa
  }
  this.getNameRu = () => {
    return this.data.nameRu
  }
  this.getSystemNumber = () => {
    return this.data.systemNumber
  }
  // this.getIndividual = () => {
  //   return this.data.individual
  // }
  // this.getBusiness = () => {
  //   return this.data.business
  // }
  // this.getForAdmin = () => {
  //   return this.data.forAdmin
  // }
  this.getAllowPrivatBank = () => {
    return this.data.allowPrivatBank
  }

  this.getAccountBankTypesSelected = () => {
    return this.data.accountBankTypesSelected
  }
  this.getEnable = () => {
    return this.data.enable
  }

  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }
  this.setTypeId = (val) => {
    this.data.typeId = val
  }
  this.setName = (val) => {
    this.data.name = val
  }
  this.setNameEn = (val) => {
    this.data.nameEn = val
  }
  this.setNameUa = (val) => {
    this.data.nameUa = val
  }
  this.setNameRu = (val) => {
    this.data.nameRu = val
  }
  this.setSystemNumber = (val) => {
    this.data.systemNumber = val
  }
  // this.setIndividual = (val) => {
  //   this.data.individual = val
  // }
  // this.setBusiness = (val) => {
  //   this.data.business = val
  // }
  // this.setForAdmin = (val) => {
  //   this.data.forAdmin = val
  // }
  this.setAllowPrivatBank = (val) => {
    this.data.allowPrivatBank = val
  }

  this.setAccountBankTypesSelected = (val) => {
    this.data.accountBankTypesSelected = val
  }
  this.setEnable = (val) => {
    this.data.enable = val
  }
}



/**
 * Global Setters
 */

AccountBanks.prototype.setItem = function (val) {

  this.setId(val.id ? val.id : '')
  this.setTypeId(val.type_id ? val.type_id : '')
  this.setName(val.name ? val.name : '')
  this.setNameEn(val.translationStorage?.en?.name ? val.translationStorage.en.name : '')
  this.setNameUa(val.translationStorage?.ua?.name ? val.translationStorage.ua.name : '')
  this.setNameRu(val.translationStorage?.ru?.name ? val.translationStorage.ru.name : '')
  this.setAllowPrivatBank(val.use_privat_bank_out ? val.use_privat_bank_out : false)
  this.setSystemNumber(val.permission ? val.permission : '')
  this.setAccountBankTypesSelected(val.type ? val.type : '')
  this.setEnable(val.enable ? val.enable : false)

  //
  // if (val['logo_base64'])
  //   this.setShippingCompanyDownloadFiles(val['logo_base64'])
}


AccountBanks.prototype.setShippingCompanyDownloadFiles = function (val) {
  this.data.Files.pushDownloadFiles(val)
}

AccountBanks.prototype.removeShippingCompanyFile = function () {
  this.data.Files.removeDownloadFile(1)
}


/**
 * Functions
 */


/**
 * Validations
 */

AccountBanks.prototype.firstValidation = function () {

  let data = this.data

  let validationItems = {
    name: data.name,
    nameEn: data.nameEn,
    nameUa: data.nameUa,
    nameRu: data.nameRu,
    // systemNumber: data.systemNumber,
    accountBankTypesSelected: data.accountBankTypesSelected,
  }

  let validationOptions = {
    name: {type: ['empty']},
    nameEn: {type: ['empty']},
    nameUa: {type: ['empty']},
    nameRu: {type: ['empty']},
    // systemNumber: {type: ['empty']},
    accountBankTypesSelected: {type: ['empty']},
  }


  return (this.checkValid(validationItems, validationOptions) && this.data.Files.fileSizeValidation())
}


/**
 * Prepare Data
 */

AccountBanks.prototype.prepareSave = function () {

  let data = {
    "type_id": this.getAccountBankTypesSelected().id,
    "name": this.getName(),
    "permission": this.getSystemNumber(),
    "use_privat_bank_out": this.getAllowPrivatBank(),
    "enable": this.getEnable(),

    "translations": {
      "1": {
        "name": this.getNameEn()
      },
      "2": {
        "name": this.getNameUa()
      },
      "3": {
        "name": this.getNameRu()
      }
    },
  }

  if(this.data.Files.getFiles().length > 0 ){
    data['logo_base64'] = `data:${this.data.Files.getFiles()[0].item[0].type};base64,` + this.data.Files.getFiles()[0].base64String
    data['svg_logo'] = this.data.Files.getFiles()[0].binaryData
  }

  return data
}
