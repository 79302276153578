export const accountBanksMixin = {

  methods: {
    save(edit = false){

      if(!this.AB.firstValidation()) return
      let data = this.AB.prepareSave()
      let saveType = 'createAccountBanks'

      if (edit) {
        data = {
          id: this.AB.getId(),
          data: data
        }
        saveType = 'updateAccountBanks'
      }

      this.$store.dispatch(saveType, data).then(response => {
        if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if (edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }

            this.$router.push(this.$store.getters.GET_PATHS.accountTypes)
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },


    removeItem(id){
      let text = {
        title: 'accountTypes_DeleteAccountBank',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('deleteAccountBanks', id).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            this.$emit('reload')
            this.openNotify('success', 'common_notificationRecordDeleted')

            this.$emit('reload')

          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },
  },

}
