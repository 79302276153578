<template>
  <div class="fragment">
    <template v-if="$store.getters.getAccountBanks.length > 0 && $store.getters.getAccountBanksLoading !== true">
      <div class="custom-row accounts-table pt-4">
        
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20"
             v-for="(item, index) in $store.getters.getAccountBanks" :key="index"
        >
          <div class="case-cart">
            <div class="case-cart__inner"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['accountTypes_enabledBank', 'accountTypes_disabledBank'])"></div>
              <div class="case-cart__status">
                <v-popover
                    class="site-tooltip"
                    offset="5"
                    placement="top-left"
                    trigger="hover"
                >
                <span>
                  <div>
                    <StatusIcoBtn v-if="item.enable" :type="'valid'"/>
                    <StatusIcoBtn v-else :type="'no-info'"/>
                  </div>
                </span>
                  <template slot="popover">
                    <div>
                      <div class="status-tooltip" v-if="item.enable">
                        <StatusIcoBtn :type="'valid'" class="mr-2"/>
                        {{$t('accountTypes_enabledBank.localization_value.value')}}
                      </div>
                      <div class="status-tooltip" v-else>
                        <StatusIcoBtn :type="'no-info'" class="mr-2"/>
                        {{$t('accountTypes_disabledBank.localization_value.value')}}
                      </div>
                    </div>
                  </template>
                </v-popover>

              </div>
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img" v-if="item.svg_logo"
                           v-html="item.svg_logo"
                      ></div>
                      <div class="case-cart__accounts-type-img" v-else>
                        <img src="/img/dashboard-group/balance-icon.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                        {{ item.currentTranslate.name }}
                      </span>
                    </div>
                  </div>
  
                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['accountTypes_Name'])"></div>
                          {{$t('accountTypes_Name.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          {{ item.name }}
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['accountTypes_PrivatBank'])"></div>
                          {{$t('accountTypes_accountBankTypes.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          {{item.type.name}}
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['accountTypes_PrivatBank'])"></div>
                          {{$t('accountTypes_PrivatBank.localization_value.value')}}
                        </div>
                        <div class="case-cart__content"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['common_yes', 'common_no'])"></div>
                          <template v-if="item.use_privat_bank_out">
                           {{$t('common_yes.localization_value.value')}}
                          </template>
                          <template v-else>
                            {{$t('common_no.localization_value.value')}}
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_delete'])"></div>
                  <CaseCartButton
                      class="secondary-brown"
                      :value="$t('common_delete.localization_value.value')"
                      @click.native="removeItem(item.id)"
                      :ico="true"
                      :typeIco="'delete-brown'"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_edit'])"></div>
                  <router-link :to="$store.getters.GET_PATHS.accountTypes + '/' + item.id">
                    <CaseCartButton
                        :value="$t('common_edit.localization_value.value')"
                        :ico="true"
                        :typeIco="'edit'"
                    />
                  </router-link>

                </div>
              </div>
            </div>
          </div>
        </div>
  


      </div>
      <div style="display: none;">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">

                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/privat-bank.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Privat Bank
                        </span>
                    </div>
                  </div>


                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['accountTypes_Individual'])"></div>
                          {{$t('accountTypes_Individual.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['accountTypes_Business'])"></div>
                          {{$t('accountTypes_Business.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['accountTypes_ForAdmin'])"></div>
                          {{$t('accountTypes_ForAdmin.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['accountTypes_PrivatBank'])"></div>
                          {{$t('accountTypes_PrivatBank.localization_value.value')}}
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_delete'])"></div>
                  <CaseCartButton
                      class="secondary-brown"
                      :value="$t('common_delete.localization_value.value')"
                      @click.native="deleteItem(item.id)"
                      :ico="true"
                      :typeIco="'delete-brown'"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_edit'])"></div>
                  <CaseCartButton
                      :value="$t('common_edit.localization_value.value')"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/otp-bank.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          OTP Bank
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/raiffeisen-bank.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Raiffeisen Bank Oval
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/alfa-bank.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Alfa Bank
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/monobank.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Monobank
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/payoneer.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Payoneer
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/swift.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Swift
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/ukrgasbank.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Ukrgasbank
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/ebay.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Ebay Invoice
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/paypal.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Pay Pal
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/etsy.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Etsy
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/oshchad-bank.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Oshchad Bank
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/a-bank.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          A-bank
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/kredobank.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Kredobank
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/e-payments.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          E-payments
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/todobank.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Todobank
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/visa.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Bento Visa Card
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/bank-pivdenny.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Bank Pivdenny
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/kit-group.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Kit Group
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/pumb.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Pumb
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/credit-agricole.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Credit agricole
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/dnepr-bank-credit.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Bank Credit Dnepr
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/ursibbank.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Ursibbank
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--sxl-25 custom-col--xl-20">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">
                <div class="case-cart__content-block">
                  <div class="case-cart__head">
                    <div class="case-cart__title">
                      <div class="case-cart__accounts-type-img">
                        <img src="/img/company-icons-group/account-types/ria-international.svg" alt="img">
                      </div>
                      <span class="color-black pr-2">
                          Ria International
                        </span>
                    </div>
                  </div>

                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Individual
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          New Business
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          for Admin
                        </div>
                        <div class="case-cart__content">
                          no
                        </div>
                      </div>
                      <div class="case-cart__col custom-col  custom-col--50">
                        <div class="case-cart__label">
                          Privat bank
                        </div>
                        <div class="case-cart__content">
                          yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="deleteItem"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Edit'"
                      :ico="true"
                      :typeIco="'edit'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getAccountBanksCommonList.next_page_url !== null && $store.getters.getAccountBanks.length > 0"
              @click.native="$emit('showMore')"
              v-bind:class="{'disabled-btn' : $store.getters.getNextAccountBanksPage}"
              :count="$store.getters.getAccountBanksCommonList.total - $store.getters.getAccountBanksForPage < $store.getters.getAccountBanksForPage ?
                  $store.getters.getAccountBanksCommonList.total - $store.getters.getAccountBanksForPage:
                  $store.getters.getAccountBanksForPage"
          />
        </div>
      </div>

    </template>

    <template v-if="$store.getters.getAccountBanksLoading === false && $store.getters.getAccountBanks.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>


    
  </div>
</template>

<script>
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import {accountBanksMixin} from "../../../../../../../mixins/accountBanks/accountBanksMixin";
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";


  export default {
    name: "AccountBanksHead",

    components: {
      CaseCartButton,
      ShowMore,
      NoResult,
      StatusIcoBtn,
    },

    mixins: [mixinDetictingMobile, accountBanksMixin],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },


    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

      }
    },

    methods: {

      cantDeleteProduct() {
        let text = {
          title: 'Ooops',
          txt: 'Sorry, you cannot uninstall this product',
          no: 'Ok'
        }

        let confirm = () => {
          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

    }

  }

</script>

<style scoped lang="scss">

  .case-cart__title {
    display: flex;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      padding-top: 10px;
    }
  }

  .case-cart__accounts-type-img {
    width: 32px;
    height: 32px;
    margin-right: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

  }
</style>
