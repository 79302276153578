import { render, staticRenderFns } from "./AccountBanksCreateAdmin.vue?vue&type=template&id=cde15990&scoped=true"
import script from "./AccountBanksCreateAdmin.vue?vue&type=script&lang=js"
export * from "./AccountBanksCreateAdmin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cde15990",
  null
  
)

export default component.exports