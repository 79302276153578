<template>
  <AccountBanksCreateAdmin
          :AB="AB"
          @save="save"
          :optionsAccountBankTypes="optionsAccountBankTypes"
  />
</template>

<script>
  import AccountBanksCreateAdmin from "./AccountBanksCreateAdmin/AccountBanksCreateAdmin";
  import {AccountBanks} from "../../models/AccountBanks";
  import {accountBanksMixin} from "../../../../../mixins/accountBanks/accountBanksMixin";
  import {ACCOUNT_BANK_TYPE} from "../../../../../staticData/staticVariables";

  export default {
    name: "AccountBanksCreate",
    components: {
      AccountBanksCreateAdmin,
    },

    mixins: [accountBanksMixin],

    data() {
      return {
        AB: new AccountBanks(),
        optionsAccountBankTypes: [],
        ACCOUNT_BANK_TYPE: ACCOUNT_BANK_TYPE,
        filterBankType: [],
      }
    },

    mounted() {
      /**
       * When creating, only the Ukrainian bank type is allowed
       */
      this.$store.dispatch('fetchAccountBankType').then((response) => {
        this.getRespPaginateData(response).map((item) => {
          if (item.id !== ACCOUNT_BANK_TYPE.UKRAINIAN_BANK.id) return
          this.filterBankType.push(item)
        })
        this.optionsAccountBankTypes = this.filterBankType
      }).then(() => {
        this.AB.setAccountBankTypesSelected(this._.first(this.filterBankType))
      }).catch(error => this.createErrorLog(error))
    },

    methods: {

    },

  }
</script>

<style scoped>

</style>
