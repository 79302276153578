<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
              class="clear-992"
              :backgroundImage="'balance'"
      >
        <template slot="body">
          <UserInfo
              :AB="AB"
          />
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['accountTypes_backAccountBanks'])"></div>
                <router-link :to="$store.getters.GET_PATHS.accountTypes" class="detail-page__head-back">
                  <LinkBack
                          :value="$t('accountTypes_backAccountBanks.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['accountTypes_AccountTypes'])"></div>
              {{$t('accountTypes_AccountTypesEdit.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <StepContent
                  :AB="AB"
                  :optionsAccountBankTypes="optionsAccountBankTypes"
          />
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
              <router-link :to="$store.getters.GET_PATHS.accountTypes"
                           class="site-link site-link--alt"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_edit'])"></div>
              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('common_edit.localization_value.value')"
                      @click.native="$emit('save')"
                      v-bind:class="{'disabled-btn' : $store.getters.getAccountBanksBtn}"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import UserInfo from "../../components/UserInfo/UserInfo";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import StepContent from "../../components/StepContent/StepContent";

  export default {
    name: "AccountBanksEditAdmin",
    components: {
      StepContent,
      // DefaultInput,
      MainButton,
      CardLeftBlock,
      UserInfo,
      CardRightBlock,
      LinkBack,
      // DefaultCheckbox,
      // DropZone,
    },

    props: {
      AB: Object,
      optionsAccountBankTypes: Array
    },

    data() {
      return {

      }
    },

    methods: {
      changeImg(files) {
        this.files = files
      },

    },
  }
</script>

<style scoped lang="scss">

</style>
